import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import { useAuthStore } from "@/store/auth";
import { useRegisterStore } from "@/store/register";
import { fetchEmployerId, fetchThemeData, fetchEmployerAttributes, getEmployerSystemAttributes, fetchCategoryAction } from "../services/register";
import { fetchSubdomainInfoByClientName } from "@/services/subdomain";
import swal from "sweetalert";
import { getActivePinia } from 'pinia'
import router from "@/routes/routes";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        isFromSubdomain: false,
        clientName: null
      };
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e;
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        
        this.auth0Client.logout(o);
        
        //instantiation to get active pinia stores
        const pinia = getActivePinia()
        
        //reset the stores
        pinia._s.forEach((store) => store.$reset());
        
        //clear localStorage
        localStorage.clear();
        sessionStorage.clear();
      },
      getSubdomain(){
        let domain = window.location.href;
        if (domain.includes("://")) {
            domain = domain.split('://')[1];
        }
        const subdomain = domain.split(".").length - 1 >= 3 ? domain.split('.')[1] : domain.split('.')[0];
        
        return subdomain != 'member' && !subdomain.includes('localhost') && !subdomain.includes('lifepoint')  && !subdomain.includes('membertest') && subdomain != 'memberdev' && subdomain != 'memberuat' ? subdomain : "";
      },
      async createAuth0ClientMethod(connectionName=""){

        connectionName = !connectionName ? this.getSubdomain() : connectionName;

        //check if user logged in via subdomain/SSO
        if(connectionName){
          this.isFromSubdomain = true;
          this.clientName = connectionName;
        }

        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = await createAuth0Client({
          ...options,
          client_id: options.clientId,
          redirect_uri: redirectUri,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
          connection: connectionName
        });

        try {
          // If the user is returning to the app after authentication..
          if (
            window.location.search.includes("code=") &&
            window.location.search.includes("state=")
          ) {
        
            localStorage.setItem("returningFromLogin", true);
            // handle the redirect and retrieve tokens
            const { appState } = await this.auth0Client.handleRedirectCallback();

            if (appState && appState.targetUrl)
               localStorage.setItem("redirectUrl", appState.targetUrl);
  
            this.error = null;
            // Notify subscribers that the redirect callback has happened, passing the appState
            // (useful for retrieving any pre-authentication state)
            onRedirectCallback(appState);
          }
          else
            localStorage.setItem("returningFromLogin", false);
        } catch (e) {
          this.error = e;
        } finally {
          // Initialize our internal authentication state
  
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.user = await this.auth0Client.getUser();
          this.loading = false;
          const register = useRegisterStore();
          const state = useAuthStore();
  
          let shouldClearCache = false;
          //clear pinia states / cache if last login is longer than 8 hours
          if(state.lastLogin){
            let timeDifference = (new Date() - new Date(state.lastLogin));
            if(timeDifference){
              let differenceInMinutes = (timeDifference / 60000);
              if(differenceInMinutes > 480)
                shouldClearCache = true;
            }
          }

          if(shouldClearCache){
            const pinia = getActivePinia()
            pinia._s.forEach((store) => store.$reset());
          }

          state.lastLogin = new Date().toLocaleString();

          state.isAuthenticated = true;
          // accessToken
  
          this.$auth.getTokenSilently().then((tok) => {
            state.accessToken = tok;
  
            //TODO: the below returns true false if the token is set. need to add code here to show error if false
            // state.setTokenObject(tok);
          });
          // Idtoken
  
          let idToken;
          this.$auth.getIdTokenClaims().then((response) => {
            state.idToken = response.__raw;
            idToken = response.__raw;
            if (this.$auth.user.email && idToken) {
              state.userEmail = this.$auth.user.email;
              let subDomain = this.getSubdomain().toLowerCase();
              if(this.isFromSubdomain || (subDomain && response.sub && response.sub.toLowerCase().includes(subDomain)))
              {
                fetchSubdomainInfoByClientName(subDomain, idToken)
                .then((response) => {
                  if(response.data.data.logOutUrl){
                    state.logoutUrl = response.data.data.logOutUrl;
                  } else{
                    state.logoutUrl = window.location.origin;
                  }

                })
                .catch(() => this.state.logoutUrl = window.location.origin)
              }else{
                state.logoutUrl = window.location.origin;
              }

              fetchEmployerId(this.$auth.user.email, idToken)
                .then((response) => {
                  if (response) {
                    if (
                      response.data.data.employerID ||
                      response.data.data.memberID
                    ) {
                      state.employerId = response.data.data.employerID;
                      state.memberId = response.data.data.memberID;
                      // commented out because it rendered the logo state twice, causing the glitch upon login where we would see the correct logo and then the default revive logo and then the correct logo again.
                      // state.mainLogo = response.data.data.mainLogo;
                      if (!state.startPageToken){
                      fetchCategoryAction(response.data.data.memberID, idToken)
                      .then((res) => {
                        if(res.data){
                          const path = new URL(res.data)?.pathname;
                          const route = router?.resolve(path);
                          if (route.route.matched.length > 0) {
                            router.push(path);
                          } else {
                            window.open(res.data, '_blank', 'noopener');
                          }
                        }
                      });}
                    } else {
                      swal({
                        text: "User Does not exist",
                        icon: "error",
                      });
                    }
  
                    // Calling Theme api and setting color veriables in localstorage and pinia
  

                    fetchThemeData(response.data.data.employerID, idToken)
                      .then((result) => {
                        state.mainLogo = result.data.data?.mainLogo;
                        state.mobileLogo = result.data.data?.mobileLogo;
                        state.phoneNumber = "888-220-6650";
                        state.theme.primary = "#1e1e1d";
                        state.theme.secondary =  "#171b1d";
                        state.theme.secondaryTextColor = "#fbfbfb";
                        state.theme.background =  "#fbfbfb";
                        state.theme.headerColor = "#fbfbfc";
                        state.theme.footerColor = "#f4f4f4";
                        state.theme.headerTextColor = "#171b1d";
                        state.theme.footerTextColor = "#171b1d";
                        state.theme.primaryActionColor = "#171b1d";
                        state.theme.secondaryActionColor = "#dfff00";

                        if (result.data.data.enableCustomization) {
                          try {
                            state.phoneNumber = result.data.data?.phoneNumber?.length > 1 ? result.data.data?.phoneNumber.split('+1 ')[1]?.replace(/ /g, "-") : "888-220-6650";
                          } catch {
                            state.phoneNumber = "888-220-6650";
                          }
                          state.theme.primary = result.data.data?.primaryColor?.hex ?? "#1e1e1d";
                          state.theme.secondary = result.data.data?.secondaryColor?.hex ?? "#171b1d";
                          state.theme.secondaryTextColor = result.data.data?.secondaryTextColor?.hex ?? "#fbfbfb";
                          state.theme.background = result.data.data?.backgroundColor?.hex ?? "#fbfbfb";
                          state.theme.headerColor = result.data.data?.headerColor?.hex ?? "#fbfbfc";
                          state.theme.footerColor = result.data.data?.footerColor?.hex ?? "#f4f4f4";
                          state.theme.headerTextColor = result.data.data?.headerTextColor?.hex ?? "#171b1d";
                          state.theme.footerTextColor = result.data.data?.footerTextColor?.hex ?? "#171b1d";
                          state.theme.primaryActionColor = result.data.data?.primaryActionColor?.hex ?? "#171b1d";
                          state.theme.secondaryActionColor = result.data.data?.secondaryActionColor?.hex ?? "#dfff00";
                        } 
                      })
                        .finally(() => state.theme.loadComplete = true)
                        if (!state.startPageToken){
                          if(response.data.data.employerID){
                            fetchEmployerAttributes(response.data.data.employerID, idToken)
                            .then((response) => {
                              if(response) {
                                register.employerProfileAttribute.profileEdit = typeof response.data.data.employerProfileAttribute.profileEdit == 'undefined' || response.data.data.employerProfileAttribute.profileEdit == null || !response.data.data.employerProfileAttribute.profileEdit ? false : response.data.data.employerProfileAttribute.profileEdit;
                                register.employerProfileAttribute.dependentAddOrEdit = typeof response.data.data.employerProfileAttribute.dependentAddOrEdit == 'undefined' || response.data.data.employerProfileAttribute.dependentAddOrEdit == null ? 0 : response.data.data.employerProfileAttribute.dependentAddOrEdit;
                                register.employerProfileAttribute.displayDependent =  typeof response.data.data.employerProfileAttribute.displayDependent == 'undefined' || response.data.data.employerProfileAttribute.displayDependent == null ||!response.data.data.employerProfileAttribute.displayDependent ? false : response.data.data.employerProfileAttribute.displayDependent;
                              } else {
                                swal({
                                  text: "Employer Attributes Does not exist",
                                  icon: "error",
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                              swal({
                                // title: "Oops!",
                                text: "Something went wrong getting attributes ! Try after sometime",
                                icon: "warning",
                              }).then(() => {
                                window.location.href = process.env.VUE_APP_MARKET_WEBSITE;
                              });
                            });
                            
                            //load employer system attributes
                            getEmployerSystemAttributes(response.data.data.employerID)
                            .then((res) => {
                              register.employerSystemAttribute = res.data.data.employerSystemAttribute;
                            });
                          }
                        }
                    }
                  })
                .catch(() => {
                  swal({
                    text: "Something went wrong ! Try after sometime",
                    icon: "warning",
                  }).then(() => {
                    window.location.href = process.env.VUE_APP_MARKET_WEBSITE;
                  });
                });
            }
          });
        }
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    created() {
      this.createAuth0ClientMethod();
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
