import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";

//fetch product details on homepage api called
export const fetchProducts = (employId, memberId, idToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_LANDING_PAGE_PRODUCT_URL, {
        params: {
          employerID: employId,
          memberID: memberId,
        },
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((response) => {
        datadogLogs.logger.info(
          `Called GetProductStockByEmployer for employerId=${employId} Response : ${response}`
        );
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called GetProductStockByEmployer for employerId=${employId} Response : ${err}`
        );
        reject(err);
      });
  });
};

export const fetchProfileMemberDetails = (email, idToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_GET_MEMBER_BY_EMAIL_PROFILE, {
        params: {
          email: email,
        },
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((response) => {
        datadogLogs.logger.info(
          `Called GetMemberByEmail for email=${email} Response : ${response}`
        );
        resolve(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(
          `Called GetMemberByEmail for email=${email} Response : ${err}`
        );
        reject(err);
      });
  });
};
